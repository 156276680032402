// extracted by mini-css-extract-plugin
export var container = "fsaas-module--container--1vQiF";
export var movie1 = "fsaas-module--movie1--2RghX";
export var moviebutton = "fsaas-module--moviebutton--3kR1t";
export var moviebutton1 = "fsaas-module--moviebutton1--2_Hv3";
export var header = "fsaas-module--header--1F9sq";
export var slideInRight = "fsaas-module--slideInRight--EX7wy";
export var keyvisual = "fsaas-module--keyvisual--3QTQk";
export var main = "fsaas-module--main--1lN5q";
export var sidebar = "fsaas-module--sidebar--1ablU";
export var footer = "fsaas-module--footer--3tTsq";
export var box11 = "fsaas-module--box11--3Qqgd";
export var SlideIn = "fsaas-module--SlideIn--1-g1G";
export var box12 = "fsaas-module--box12--3JiGe";
export var box21 = "fsaas-module--box21--29UQe";
export var box22 = "fsaas-module--box22--3kAim";
export var box32 = "fsaas-module--box32--1s50c";
export var box31 = "fsaas-module--box31--CU8Js";