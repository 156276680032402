import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout4';
import SEO from '../components/seo';
import { container, header, movie1, moviebutton, moviebutton1, main, sidebar, box11, box12, box21, box22, box31, box32, footer } from './fsaas.module.css';
import { StaticImage } from "gatsby-plugin-image";


const App = (props: any) => {
  const { data } = props;

////// パラメータ取得 /////////////////////////
  const paramm = typeof window !== 'undefined' ? window.location.search : '';
  const param = decodeURI(paramm)
  console.log(param);
  const query1 = param
    .slice(1)
    .split('&')
    .map((str) => [str.split('=')[0], str.split('=')[1]])
    .reduce((acc, a) => {
      acc[a[0]] = a[1];
      return acc;
    }, {} as QueryString);
  
  console.log(query1);
//////////////////////// パラメータ取得 //////


  return (
    <Layout>
      <SEO
        title="ジオグラフの使い方"
        description="地域見える化GIS「ジオグラフ」の使い方を解説しているページです。"
      />





<div className={container}>

    <header className={header}>
    <h1>テストページ</h1>
    </header>

<movie className={movie1}>
  <h2>
    動画による使い方解説
  </h2>

  <div>
  <a href="https://youtu.be/UK90VaTUY6U" className={moviebutton}>
  １：テーマ選択、区域選択</a>
  <br/>
  <br/>
  <a href="https://youtu.be/pVnIi8QIFNg" className={moviebutton}>
  ２：地図の様々な表示方法
  </a>
  <br/>
  <br/>
  <a href="https://youtu.be/DUyZG2oeuWE" className={moviebutton}>
  ３：ジオグラフの使い方
  </a>
  </div>


</movie>


    <main className={main}>

    <StaticImage src="../images/saas/tuto4.png" alt="img" />

    </main>
    <aside className={sidebar}>
      <p>４.ジオグラフでは他にも、２つの地域を比較したり、国土全体で眺めたりもできる、５種類のモードを備えています。<br/>
      この「モード」は、上段のタブで変えられます。<br/>
      はじめは「自分で地域を選ぶ」モードとなっています。<br/>
      気になるタブを開いてみましょう。<br/>
      なお、「典型例とその解説」モードでは、選んだテーマについての解説も読むことができます。</p>
    </aside>


    <box11 className={box11}>
    <StaticImage src="../images/saas/tuto1.png" alt="img" />
    </box11>

    <box12 className={box12}>
    <p>1. ジオグラフでは、左側に凡例、右側に地域の状況を３次元グラフで「見える化」させた地図が配置されています。<br/>
    <br/>【トップページ】でタイトル部分をクリックし、【テーマ選択ページ】で「見える化」させたいテーマを選び終えると、こちらの【プレビューページ】が表示されます。<br/>
    <br/>
    【プレビューページ】では、まず、見たい地域の市町村名もしくは都道府県名を入力しましょう。３次元グラフの描画が始まります。<br/>
    なお、最初は、データ量が小さくなる面積の小さな市町村で、試しに入力してみると良いでしょう。
    <br/><br/></p>
    </box12>

    <box21 className={box21}>
<p>
2. ３次元グラフが描画され始めたら、凡例を確認しましょう。こちらの例では、高さは人口、色は昼夜間人口比となっていることがわかります。<br/><br/>
昼夜間人口比とは、昼間と夜の人口の比率のことで、昼/夜で計算します。すなわち大きいほど昼間の人口が多いことを示します。<br/>
<br/>
高い＝人口が多い<br/>
低い＝人口が少ない<br/>
赤い＝昼間の人口が夜より多い<br/>
青い＝昼間の人口が夜より少ない<br/>
<br/>
最初のうちは、凡例と地図を交互に見ながら、読み取り方に慣れていきましょう。
</p>
</box21>

    <box22 className={box22}>
    <StaticImage src="../images/saas/tuto5.png" alt="img" />
    </box22>


    <footer className={footer}>
    さあ、それではジオグラフを使って地域を探究していきましょう。<br/>
    以下は、代表的なテーマへのリンクとなります。<br/>
<br/>

  </footer>
</div>

    </Layout>
  );
};


  
export default App;